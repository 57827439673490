import App from "App";
import { AuthProvider } from "contexts/auth";
import "index.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { attribution } from "utils/attribution";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Attribution script to track referrer and utm tags
attribution();
