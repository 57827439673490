import * as Sentry from "@sentry/react";
import { Button, Result } from "antd";
import React, { FC, ReactElement } from "react";

function FallbackComponent() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Désolé, quelque chose ne s'est pas déroulé comme prévu."
      extra={
        <Button href="/" type="primary">
          Retourner sur la page principale
        </Button>
      }
    />
  );
}

const ErrorBoundary: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
