function getParameterByName(name: string) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname: string, cvalue: string) {
  const d = new Date();
  d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 = number of days
  const expires = `expires=${d.toUTCString()}`;
  // Using the new SameSite attribute: https://web.dev/samesite-cookie-recipes/
  document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=Strict`;
  // Fallback for legacy browsers: https://web.dev/samesite-cookie-recipes/
  document.cookie = `${cname}=${cvalue};${expires};path=/;Secure`;
}

export function attribution() {
  // Give the URL parameters letiable names
  let utm_source = getParameterByName("utm_source");
  let utm_medium = getParameterByName("utm_medium");
  let utm_campaign = getParameterByName("utm_campaign");

  // Set the cookies
  if (utm_source !== "" && getCookie("utm_source") === "") {
    setCookie("utm_source", utm_source);
  }
  if (utm_medium !== "" && getCookie("utm_medium") === "") {
    setCookie("utm_medium", utm_medium);
  }
  if (utm_campaign !== "" && getCookie("utm_campaign") === "") {
    setCookie("utm_campaign", utm_campaign);
  }
  if (document.referrer !== "" && getCookie("referrer") === "") {
    setCookie("referrer", document.referrer);
  }
}
