import { Me } from "types";
import axios from "utils/setupAxios";

export async function update(userId: number, values: any): Promise<Me> {
  return axios.put(`/users/${userId}`, values).then((res) => res.data);
}

export async function getMe(): Promise<Me> {
  return axios.get("/users/me").then((res) => res.data);
}

export async function setCompany(values: any): Promise<Me> {
  return axios.post(`/users/set-company`, values).then((res) => res.data);
}

const userService = { getMe, setCompany, update };
export default userService;
