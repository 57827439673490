import axios from "axios";
import { getJwtToken, setJwtToken } from "./storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use((config) => {
  const token = getJwtToken();

  if (token) {
    config.headers = Object.assign(
      {
        Authorization: `Bearer ${token}`,
      },
      config.headers
    );
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response?.status;
    const message = error.response?.data?.message;

    const UNAUTHORIZED = 401;
    if (status === UNAUTHORIZED) {
      setJwtToken("");
      window.location.href = "/login";
    } else if (message === "Current company is not set") {
      window.location.href = "/change-company";
    }
    throw error;
  }
);

export default instance;
